const { alert } = require('site/components/alerts');
const formValidation = require('base/components/formValidation');

/**
 * Sets a cookie with the given name, value, and expiration days.
 * 
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 * @param {number} days - The number of days until the cookie expires.
 */
function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Expiry in days
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
}

/**
 * Retrieves the value of a specified cookie.
 * 
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string} - The value of the cookie if found, otherwise an empty string.
 */
function getCookie(name) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.indexOf(`${name}=`) === 0) {
            return cookie.substring(name.length + 1);
        }
    }
    return '';
}

/**
 * Show the joining opt confirmation pop up
 */
function checkForJoining() {
    const isJoining = window.localStorage.getItem('joining');
    const isCookieExpired = getCookie('hide_homepage_popup') !== 'true' ? true : false;

    if (isCookieExpired && (!isJoining || isJoining == 'false')) {
        $('#joiningConfirmationModal-Home').modal('show');
    }
}

function checkForClose() {
    $('.joining-opt__close-btn').on('click', function () {
        // Show the second modal after the first is closed
        $('#textSignUpPromoModal').modal('show');

        const url = $(this).data('url');

        const data = {
            homepage_popup: true,
        }

        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            data,
            success: (response) => {
                const frequency = response.frequency;
                setCookie('hide_homepage_popup', 'true', frequency);
            },
            error: (error) => {
                alert({
                    context: 'alert-danger',
                    message: error.responseJSON.message,
                    delay: 2000,
                });
            },
        });
    });
}

function bindEvents() {
    const $form = $('.js-submit-joining-home-form');
    $form.on('submit', (event) => {
        event.preventDefault();
        const url = $form.attr('action');
        const isOptForNotification = $('#joiningOptCheck-message').is(':checked') || $('#joiningOptCheck-email').is(':checked');
        if (isOptForNotification === false) {
            alert({
                context: 'alert-danger',
                message: 'Please select at least one option',
                delay: 2000,
            });
            return;
        }
        $form.spinner().start();
        $.ajax({
            url,
            type: 'POST',
            dataType: 'json',
            data: $form.serialize(),
            success: (data) => {
                $form.spinner().stop();
                if (data.fields) {
                    formValidation($form, data);
                    return;
                }

                // Hide modal
                $('#joiningConfirmationModal-Home').modal('hide');
                window.localStorage.setItem('joining', true);

                // If success then show message and redirect user to my account page 
                if (data.success) {
                    alert({
                        context: 'alert-danger',
                        dismissible: true,
                        message: data.message,
                    });

                    window.location.href = data.redirectUrl;
                } else {
                    alert({
                        context: 'alert-danger',
                        message: data.message,
                        delay: 4000,
                    });

                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    }
                }
            },
            error: (error) => {
                $form.spinner().stop();
                alert({
                    context: 'alert-danger',
                    dismissible: true,
                    message: error.responseJSON.message,
                });
            },
        });
    });
}

module.exports = {
    checkForJoining,
    bindEvents,
    checkForClose,
};
